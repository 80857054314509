import React, { useState, useEffect, Fragment } from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import BlogContent from "../components/blog-content";
import Icon from "../components/icon.tsx";
import Comments from "../components/comments";
import CommentsForm from "../components/comments-form";
import Thumbnail from "../components/thumbnail";
import ProductsToc from "../components/products-toc";
import Breadcrumbs from "../components/breadcrumbs";
import AdvDis from "../components/adv-dis";
import Out from "../components/out-product";
import ProductInputs from "../components/product-inputs";
import { ExcelFormulas, APR } from "../utils/excel-formulas";
import ProductCheckboxComparison from "../components/product-comparison-checkbox";
import useDescription from "../hooks/useDescription";
import Translate from "../components/translate";
import useMedia from "../hooks/useMedia";
import scrollTo from "gatsby-plugin-smoothscroll";
import { SCROLL_CLASS, DIRECTION_DOWN_CLASS, DIRECTION_UP_CLASS, } from "../utils/classes";
import SimilarProducts from "../components/similar-products";
import resolveUrl from "../utils/urls/resolve-url";
import resolveProductUrl from "../utils/urls/resolve-product-url";
import Layout from "../components/layout";
import Faq from "../components/faq";

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";

export const Head = ({ ...props }) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/credit/${props.pageContext.mysqlId}.png`} />}
  </BaseHead>
);

const ConsolidationLoan = ({
  data: { mysqlCredit, faq, allMysqlDescriptionCredit, allMysqlComments, allMysqlBlog, allMysqlCredit },
  pageContext: { product, mysqlId, breadcrumbs },
  location: { pathname },
}) => {
  const {
    name,
    mysqlImage,
    bank,
    rating,
    percent,
    time_min,
    time_max,
    amount_min,
    amount_max,
    aprc,
    fee_min,
    disadvantages,
    advantages,
    inactive_reason
  } = mysqlCredit;

  const [isOpenToc, setIsOpenToc] = useState(false);

  const [productSettings, setProductsSettings] = useState({ 
    input: { 
      amount_min,
      amount_max,
      time_min,
      time_max,
      amount: Math.max(amount_min, 25_000), 
      time: Math.max(time_min, 60)
    }
  });

  const [details, setDetails] = useState([]);

  const calculateDetails = () => {
    const amount = productSettings.input.amount;
    const time = productSettings.input.time;
    const provision = fee_min / 100;
    const percentage = percent / 100;

    const installment =
      Math.round(
        ExcelFormulas.PMT(percentage / 12, time, amount + amount * provision) *
        -100
      ) / 100;
    const apr = Math.round(APR(time, amount, installment) * 10000) / 100;
    const total = Math.round(installment * time * 100) / 100;
    const cost = Math.round((total - amount) * 100) / 100;
    const today = new Date().toLocaleDateString();
    const input_details = {
      amount,
      time,
      provision,
      percentage,
      installment,
      apr,
      total,
      cost,
      today,
    };
    setDetails(input_details);
  };

  useEffect(calculateDetails, [productSettings]);

  const d = useDescription(allMysqlDescriptionCredit.nodes);

  let idTimeout = null;
  const scroll = () => {
    clearTimeout(idTimeout);

    document.body.classList.remove(DIRECTION_UP_CLASS);
    document.body.classList.add(SCROLL_CLASS, DIRECTION_DOWN_CLASS);
    scrollTo('#addComment');

    idTimeout = setTimeout(
      () => document.body.classList.remove(SCROLL_CLASS),
      2000
    );
  };

  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const [isFaq, setIsFaq] = useState(false);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)

  return (
    <Layout pathname={pathname}>
      <div className={["card-header", 'intro-products2', b.dFlex, b.justifyContentCenter].join(" ")} >
      </div>
      <div className={[b.container, "breadcrumbs-mobile-2", b.py3, b.ptLg5, "scroll-inner"].join(" ")}>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <article className={"firm-page"}>
        <div className={["local-firm-page", "blog-content", "post-content"].join(" ")}>
          <div className={b.container}>
            <div className={b.row}>
              <div className={b.colLg8}>
                <div className={[b.dFlex, b.mt3, b.alignItemsCenter].join(" ")}>
                  <div className={["icon-box-acc"].join(" ")}>
                    <Icon size={40} icon="Consolidation-loan" />
                  </div>
                  <div className={b.colSm10}>
                    <h1 className={["big-title", b.pt2].join(" ")}>
                      {name} <Translate tKey="- calculator and opinions" />
                    </h1>
                  </div>
                </div>
              </div>
              <div className={b.colLg8}>
                <div className={["firm-calc", "blueToWhite", b.mb4].join(" ")}>
                  <div className={`${b.row} product-inputs`}>
                    <div className={b.colLg8}>
                      <ProductInputs settings={productSettings} onChangeInput={setProductsSettings} productName={""} type="credit" amount_min={amount_min} amount_max={amount_max} time_min={time_min} time_max={time_max} />
                      
                      <div className={[b.row].join(" ")}>
                        <div className={[b.colLgAuto, "small-margin"].join(" ")}>
                          <small>
                            <Translate tKey="Borrowed amount" />:
                            <p className={"blue-color"}> {details.amount} {details.amount ? <Translate tKey="cu" /> : '-'}</p>
                          </small>
                          <small>
                            <Translate tKey="Loan time" />:
                            <p className={"blue-color"}> {details.time} <Translate tKey="mths" /></p>
                          </small>
                          <small>
                            <Translate tKey="Interest account" />:
                            <p className={"blue-color"}> {percent}%</p>
                          </small>
                        </div>
                        <div className={[b.colLgAuto, "small-margin-1"].join(" ")}>
                          <small>
                            <Translate tKey="Interest on the loan" />:
                            <p className={"blue-color"}> {details.cost} {details.cost ? <Translate tKey="cu" /> : '-'}</p>
                          </small>
                          <small>
                            <Translate tKey="APR" />: <p className={"blue-color"}> {aprc}%</p>
                          </small>
                          <small>
                            <Translate tKey="Total amount to be repaid" />:
                            <p className={"blue-color"}>
                              {details.amount + details.cost} {details.amount && details.cost ? <Translate tKey="cu" /> : '-'}
                            </p>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className={[b.colLg4, b.px0].join(" ")}>
                      <div className={[b.justifyContentEnd, b.dFlex, b.colSmAuto,].join(" ")}>
                        <Out className={[b.btn, "btn-success", "btn-cta", b.mt2, b.fontWeightNormal, b.textWhite, b.justifyContentCenter, b.alignItemsCenter, b.dFlex, b.mb2,].join(" ")} item={mysqlCredit} />
                      </div>
                      <div className={[b.colLgAuto, b.justifyContentEnd].join(" ")}>
                        <span className={["product-box-comparison-box", "btn-hidden", b.w100, b.mt2,].join(" ")}>
                          <ProductCheckboxComparison item={{ ...mysqlCredit, productType: 'credit' }} settings={product} />
                        </span>
                      </div>
                    </div>
                    {allMysqlBlog.nodes.length !== 0 ?
                      <div className={[b.colMd12, b.mt3].join(' ')}>
                        <div className={['account-promotions-btn', b.py1, b.px2, b.dFlex, b.justifyContentBetween, b.alignItemsCenter, 'cursor-pointer'].join(' ')} onClick={() => scroll('#section-title-4')}>
                          <div>
                            <Icon size={40} icon="Coins" className={[b.mr2, "icon-blog"].join(" ")} />
                            Aktualne promocje {name}
                          </div>
                          {!isMobileResolution ?
                            <div>
                              Sprawdź
                              <Icon size={20} icon="Angle-right" className={[b.ml1, "icon-blog"].join(" ")} />
                            </div>
                            : ''
                          }
                        </div>
                      </div>
                      : ''}
                  </div>
                </div>

                {mysqlImage && (
                  <div className={["bank-box-mobile", "margin-mobile"].join(" ")}>
                    {inactive_reason && <div className={["firm-row-likes",b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb1].join(' ')}>
                      <Icon size={16} color="#B93838" icon="Info" className={['iconToWhite', b.mr1].join(' ')} />
                      <p className={['redToWhite', 'font-size-13',b.my0,'tp-name'].join(' ')} >{inactive_reason}</p>
                    </div>}

                    <div className={["bank-padding-box", inactive_reason ? b.pt3 : ''].join(' ')}>
                      <div className="bank-padding-img">
                        <Thumbnail image={mysqlImage} alt={name} />
                      </div>
                      <div className={[b.dFlex, b.pb2, b.justifyContentCenter, b.mt3,].join(" ")} onClick={() => scroll('addComment')} role="button" tabIndex={0}>
                        <div className="stars-container">
                          {rating > 0 && (
                            <div className="star-box" style={{ width: `${rating}% ` }}>
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" />
                            </div>
                          )}
                          <div className="empty-star-box">
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" />
                          </div>
                        </div>
                      </div>
                      <span className={[b.dFlex, b.justifyContentCenter, "toc-title", "blackToWhite", 'font-size-20'].join(" ")}>
                        {(rating > 0 ? rating / 20 : 0).toFixed(1)}
                      </span>
                    </div>
                    <button className={[b.btn, "btn-light", b.mt2].join(" ")} onClick={() => { toggleForm();toggleFaq(false); }}>
                      <Icon size={20} icon="Plus-sign" className={[b.mr1, "icon-blog"].join(" ")} />
                      <Translate tKey="Add opinion" />
                    </button>
                    <Out className={[b.btn, "btn-success", "btn-cta", b.mt2, b.fontWeightNormal, b.textWhite, b.justifyContentCenter, b.alignItemsCenter, b.dFlex,].join(" ")} item={mysqlCredit} defaultDescription="Ask for credit" defaultIcon="Taking-money" />
                    <div className={[b.dFlex, b.justifyContentCenter, "blueToWhite",].join(" ")}>
                      <div className={[b.w100].join(' ')}>
                        <button className={["btn-hidden", "blueToWhite", 'iconToWhite', b.mt1, "margin-1", b.w100].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                          {!isOpenToc ? (
                            <Icon size={25} icon="Angle-down" className={[b.mr1, "icon-blog"].join(" ")} />
                          ) : ("")}
                          {!isOpenToc ? <Translate tKey="Toc" /> : ""}
                        </button>
                        {isOpenToc ? (
                          <ProductsToc selector=".firm-page .blog-content h2[id]" />
                        ) : ("")}

                        <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                          <button className={[b.mt2, "btn-hidden", "blueToWhite", 'iconToWhite',].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                            {!isOpenToc ? ("") : (
                              <Icon size={25} icon="Angle-up" className={[b.mr1, "icon-blog"].join(" ")} />
                            )}
                            {!isOpenToc ? "" : <Translate tKey="Collapse the toc" />}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                <div className={["blueToGray", "dark-li"].join(" ")}>
                  {d.offer && d.offer.description && (
                    <>
                      <h2 id="section-title-0">
                        {d.offer.header}
                      </h2>
                      <BlogContent content={d.offer.description} />
                    </>
                  )}
                  {d.liabilities && d.liabilities.description && (
                    <>
                      <h2 id="section-title-1">
                        {d.liabilities.header}
                      </h2>
                      <BlogContent content={d.liabilities.description} />
                    </>
                  )}
                  {d.cost && d.cost.description && (
                    <>
                      <h2 id="section-title-2">
                        {d.cost.header}
                      </h2>
                      <BlogContent content={d.cost.description} />
                    </>
                  )}
                  {d.register && d.register.description && (
                    <>
                      <h2 id="section-title-3">
                        {d.register.header}
                      </h2>
                      <BlogContent content={d.register.description} />
                    </>
                  )}
                  {allMysqlBlog.nodes.length !== 0 && (
                    <h2 id="section-title-4">Promocje {name}</h2>
                  )}
                  {
                    allMysqlBlog.nodes.map(({ title, description, mysqlImages, image_description, terms_path, term_normalized, url }, i) => (
                      <div key={i} className={['product-promotions', b.p0, b.pLg3, b.mt3].join(' ')}>
                        <div className={b.row}>
                          <div className={[b.colLg4, b.orderLgLast].join(' ')}>
                            <div className={["bank-promotions", b.dFlex, b.justifyContentBetween, b.alignItemsCenter, b.p3].join(' ')}>
                              <div className={[b.w50, b.mr2].join(' ')}>
                                <small className={b.textWhite}>Bonus</small>
                                <div className={[b.pt0, 'font-size-20'].join(' ')}>{image_description}</div>
                              </div>
                              <div className={['bank-list-products-box', b.p3, b.w50].join(' ')}>
                                <Thumbnail image={mysqlImages[0]} alt={title} />
                              </div>
                            </div>
                          </div>
                          <div className={b.colLg8}>
                            <div className={[b.p3, b.pLg0].join(' ')}>
                              <Link to={resolveUrl(terms_path, term_normalized, url)}>
                                <span className={[b.mt0, 'font-size-18', b.fontWeightNormal].join(' ')}>{title}</span>
                              </Link>
                              <p className="font-size-13">{description}</p>
                            </div>
                          </div>
                        </div>
                        <div className={[b.px3, b.pxLg0].join(' ')}>
                          <Link to={resolveUrl(terms_path, term_normalized, url)} className={[b.btn, 'btn-orange', 'btn-normal', 'button-288', b.mxLg0, b.mb3, b.mbLg0, b.mxAuto,].join(' ')}>
                            <Icon size={20} icon="Login" color="#FFFFFF" className={[b.mr1, "icon-blog"].join(" ")} />
                            
                            <span className={b.textWhite}>Skorzystaj z promocji</span>
                          </Link>
                        </div>
                      </div>
                    ))
                  }
                  {d.summary && d.summary.description && advantages && (
                    <>
                      <AdvDis advantages={advantages} disadvantages={disadvantages}>
                        <h2 id="section-title-5">{d.summary.header}</h2>
                        <BlogContent content={d.summary.description} />
                      </AdvDis>
                    </>
                  )}


                  {faq?.nodes.length > 0 && (
                    <>
                      <h2 id="section-title-6">
                        {d.faq && d.faq.header ? d.faq.header : ""}
                      </h2>
                      {d.faq && d.faq.description ? <BlogContent content={d.faq.description} className={b.mb3} /> : ""}
                      {faq.nodes.map(({ title, text }, i) => (
                        <Faq title={title} text={text} key={i}/>
                      ))}
                      <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                        <button className={[b.btn, "btn-light", "btn-cta", "button-256",].join(" ")} onClick={() => { toggleForm();toggleFaq(true); }}>
                          <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")} />
                          <Translate tKey="Ask your own question" />
                        </button>
                      </div>
                    </>
                  )}

                  <Comments items={allMysqlComments.nodes} descHeader={d.desc.header} formID={mysqlId} commentType="credit" />
                  <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false);toggleFaq(false); }} formID={mysqlId} commentType="credit" faq={isFaq} />

                </div>
              </div>

              {/* druga połowa strony */}
              <div className={[b.colLg4,b.mbLg5,b.pbLg5].join(' ')}>
                {mysqlImage && (
                  <>
                    <div className={["bank-box-desktop", "margin-top-icon-box", "padding-bottom", "sticky-box",].join(" ")}>
                      {inactive_reason && <div className={["firm-row-likes",b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb1].join(' ')}>
                        <Icon size={16} color="#B93838" icon="Info" className={['iconToWhite', b.mr1].join(' ')} />
                        <p className={['redToWhite', 'font-size-13',b.my0,'tp-name'].join(' ')} >{inactive_reason}</p>
                      </div>}

                      <div className={["bank-padding-box", inactive_reason ? b.pt3 : ''].join(' ')}>
                        <div className="bank-padding-img">
                          <Thumbnail image={mysqlImage} alt={name} />
                        </div>
                        <div className={[b.dFlex, b.pb2, b.justifyContentCenter, b.mt3,].join(" ")} onClick={() => scroll('addComment')} role="button" tabIndex={0}>
                          <div className="stars-container">
                            {rating > 0 && (
                              <div className="star-box" style={{ width: `${rating}% ` }}>
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" />
                              </div>
                            )}
                            <div className="empty-star-box">
                              <Icon size={20} icon="Empty-star" className={b.mr1} />
                              <Icon size={20} icon="Empty-star" className={b.mr1} />
                              <Icon size={20} icon="Empty-star" className={b.mr1} />
                              <Icon size={20} icon="Empty-star" className={b.mr1} />
                              <Icon size={20} icon="Empty-star" />
                            </div>
                          </div>
                        </div>
                        <span className={[b.dFlex, b.justifyContentCenter, "toc-title", "blackToWhite", 'font-size-20'].join(" ")}>
                          {(rating > 0 ? rating / 20 : 0).toFixed(1)}
                        </span>
                      </div>
                      <button className={[b.btn, "btn-light", b.mt2].join(" ")} onClick={() => { toggleForm();toggleFaq(false); }}>
                        <Icon size={20} icon="Plus-sign" className={[b.mr1, "icon-blog"].join(" ")} />
                        <Translate tKey="Add opinion" />
                      </button>
                      <Out className={[b.btn, "btn-success", "btn-cta", b.mt2, b.fontWeightNormal, b.textWhite, b.justifyContentCenter, b.alignItemsCenter, b.dFlex,].join(" ")} item={mysqlCredit} defaultDescription="Ask for credit" defaultIcon="Taking-money" />
                      <div className={[b.dFlex, b.justifyContentCenter, "blue-font",].join(" ")}>
                        <div>
                          <button className={["btn-hidden", "blueToWhite", "iconToWhite", b.mt1, "margin-1",].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                            {!isOpenToc ? (
                              <Icon size={25} icon="Angle-down" className={[b.mr1, "icon-blog"].join(" ")} />
                            ) : ("")}
                            {!isOpenToc ? <Translate tKey="Toc" /> : ""}
                          </button>
                          {isOpenToc ? (
                            <ProductsToc selector=".firm-page .blog-content h2[id]" />
                          ) : ("")}

                          <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                            <button className={[b.mt2, "btn-hidden", "blueToWhite", "iconToWhite",].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                              {!isOpenToc ? ("") : (
                                <Icon size={25} icon="Angle-up" className={[b.mr1, "icon-blog"].join(" ")} />
                              )}
                              {!isOpenToc ? "" : <Translate tKey="Collapse the toc" />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {bank && bank.name && (
                  <div className={"local-firm-box-1"}>
                    <span className="font-size-24"><Translate tKey="Products" /> {bank.name} </span>
                    {bank.credits.length > 0 && (
                      bank.credits.map(({ type, name, ...credit }, i) => {
                        const props =  mysqlCredit.url !== credit.url ? 
                          { to: resolveProductUrl({ bank, ...credit }) } 
                          : undefined;
                        const Component = props
                          ? Link 
                          : Fragment

                        return  (
                          <div className={[b.dFlex, b.justifyContentBetween].join(' ')} key={i}>
                            <Component {...props}>
                              <div className={[b.dFlex, b.mr3].join(" ")}>
                                {type === 2 ? <Icon size={40} icon="Cash-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                                {type === 1 ? <Icon size={40} icon="Mortgage" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                                {type === 4 ? <Icon size={40} icon="Consolidation-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                                {type === 3 ? <Icon size={40} icon="Car-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                                {type === 5 ? <Icon size={40} icon="Deposits" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                                <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{name}</p>
                              </div>
                            </Component>
                            <Out item={credit} withoutText />
                          </div>
                        );
                      })
                    )}
                    {bank.card && (
                      <div className={[b.dFlex, b.justifyContentBetween].join(' ')}>
                        <Link to={resolveProductUrl({ bank, ...bank.card })}>
                          <div className={[b.dFlex, b.mr3].join(" ")}>
                            <Icon size={40} icon="Credit-cards" className={[b.mr2, "icon-blog"].join(" ")} />
                            <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{bank.card.name}</p>
                          </div>
                        </Link>
                        <Out item={bank.card} withoutText />
                      </div>
                    )}
                    {bank.accounts.length > 0 && (
                      bank.accounts.map(({ type, name, ...account }, i) => (
                        <div className={[b.dFlex, b.justifyContentBetween].join(' ')} key={i}>
                          <Link to={resolveProductUrl({ bank, ...account })}>
                            <div className={[b.dFlex, b.mr3].join(' ')}>
                              {type === 1 ? <Icon size={40} icon="Bank-account" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 2 ? <Icon size={40} icon="Piggy-bank" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 4 ? <Icon size={40} icon="Junior-account" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 5 ? <Icon size={40} icon="Branches" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 6 ? <Icon size={40} icon="Investment" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 7 ? <Icon size={40} icon="Premium-acc" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{name}</p>
                            </div>
                          </Link>
                          <Out item={account} withoutText />
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>

            {allMysqlCredit.edges.length > 0 && (<>
              <h2><Translate tKey="Similar products" /></h2>
              <SimilarProducts items={allMysqlCredit} product={product} productType='credits' />
            </>)}
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default ConsolidationLoan;

export const query = graphql`
  query QueryDescriptionConsolidation($mysqlId: Int!, $promoCluster : String!) {

    allMysqlDescriptionCredit(filter: { credit_id: { eq: $mysqlId }}) {
      nodes {
        description,
        header
        name
      }
    }

    mysqlCredit: mysqlCredit(mysqlId: { eq: $mysqlId }) {
      name
      mysqlId
      rating
      url
      percent
      time_min
      time_max
      disadvantages
      advantages
      amount_min
      amount_max
      aprc
      fee_min
      wniosker_id
      inactive_reason
      bank {
        name
        prefix_url
        specific_url
        url
        card {
          prefix_url
          specific_url
          name
          url
          wniosker_id
        }
        accounts {
          prefix_url
          specific_url
          name
          url
          type
          wniosker_id
        }
        credits {
          prefix_url
          specific_url
          name
          url
          type
          wniosker_id
        }
      }
      mysqlImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    faq: allMysqlFaq(
      filter: { item: { eq: $mysqlId }, type: { eq: "credit" }, answer: { ne: null } },
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        title: question
        text: answer
      }
    }
    allMysqlComments(filter: {type: {eq: "credit"}, item: {eq: $mysqlId}}, sort: {order: DESC, fields: up}) {
      nodes {
        ...CommentData 
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "credit" }}) {
      nodes {
        ...SeoData
      }
    }
    allMysqlCredit(limit: 4, filter: {type: {eq: 4},mysqlId: {ne: $mysqlId}}) {
      edges {
        node {
          mysqlId
          name
          user_comments
          rating
          url
          specific_url
          prefix_url
          type
          wniosker_id
          bank {
            prefix_url
            specific_url
            url
          }
          mysqlImage {
            childImageSharp {
              gatsbyImageData(width: 103)
            }
          }
        }
      }
    }
    allMysqlBlog(
      filter: {promo_bonus: {ne: ""}, is_promo_end: { ne: 1 }, cluster: {eq: $promoCluster}}, limit: 3) {
      nodes {
        cluster
        description
        promo_bonus
        term_normalized
        terms_path
        url
        image_description
        title
        mysqlImages {
          childImageSharp {
            gatsbyImageData(width: 70)
          }
        }
      }
    }
  }
`;
